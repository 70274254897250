import isArray from 'lodash/isArray';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { DATE_FORMAT } from '@emobg/web-utils';
import { getBookingUuid, getUserProfileUuid, getUserUuid } from '@/helpers/booking/bookingHelpers';

/**
 * Prebooking payload required to call prebooking creation sdk method
 * @typedef {Object} PrebookingCreationParams
 * @property {String} operator_uuid
 * @property {String} user_uuid
 * @property {String} user_profile_uuid
 * @property {String} vehicle_category_uuid
 * @property {String} start
 * @property {String} end
 * @property {String} location_uuid
 * @property {String} destination_location_uuid
 * @property {Array} company_cost_allocations
 * @property {String} comment
 * @property {Boolean} carpooling
 * @property {Array} passengers
 * @property {Array} drivers
 */

/**
 * Data regarding Outgoing Carpooling trip
 * @typedef {Object} CarpoolingOutgoingTripData
 * @property {String} outboundAddress
 * @property {Number} outboundSeats
 */

/**
 * Data regarding Return Carpooling trip
 * @typedef {Object} CarpoolingReturnTripData
 * @property {String} returnStart
 * @property {String} returnAddress
 * @property {Number} returnSeats
 */

/**
 * Carpooling payload required to call carpooling creation sdk method
 * @typedef {Object} CarpoolingRequestParams
 * @property {String} [outbound_destination_address]
 * @property {Number} [outbound_available_seats]
 * @property {String} [return_start]
 * @property {Number} [return_available_seats]
 * @property {String} [return_origin_address]
 */

/**
 * Returns an object with params required to create a Prebooking
 * @param {Object} Reservation
 * @param {Array} CostAllocations
 * @param {Array} AdditionalDriversEmails
 * @param {Array} PassegersList
 * @param {Boolean} Carpooling
 * @return {Object} PrebookingCreationParams
 */
export const getPrebookingRequestParams = ({
  reservation, costAllocations = [], additionalDriversEmails = [], passengersList = [], carpooling = false,
}) => {
  const costAllocationsSanitize = isArray(costAllocations)
    ? costAllocations
    : [];
  const drivers = isArray(additionalDriversEmails)
    ? additionalDriversEmails
    : [];

  const passengers = isArray(passengersList)
    ? passengersList
    : [];

  const locationUuid = get(reservation, 'location.uuid');
  const destinationLocationUuid = get(reservation, 'dropOffLocation.uuid');

  return {
    ...pick(reservation, ['start', 'end', 'comment']),
    operator_id: get(reservation, 'operator.uuid'),
    user_uuid: get(reservation, 'user.uuid'),
    user_profile_uuid: getUserProfileUuid(reservation),
    vehicle_category_uuid: get(reservation, 'vehicleCategory.uuid'),
    location_uuid: locationUuid,
    destination_location_uuid: destinationLocationUuid || locationUuid,
    company_cost_allocations: costAllocationsSanitize,
    is_carpooling: carpooling,
    passengers,
    drivers,
  };
};

/**
 * Returns payload params to call to API creation carpooling
 * @param {Boolean} outboundEnabled
 * @param {Boolean} returnEnabled
 * @param {Object} booking
 * @param {Object} CarpoolingOutgoingTripData
 * @param {Object} CarpoolingReturnTripData
 * @return {Object} CarpoolingRequestParams
 */
export const getCarpoolingCreationParams = ({
  outBoundEnabled,
  returnEnabled,
  booking,
  outboundData,
  returnData,
}) => ({
  user_uuid: getUserUuid(booking),
  booking_uuid: getBookingUuid({ booking }),
  ...outBoundEnabled && {
    outbound_destination_address: get(outboundData, 'outboundAddress'),
    outbound_available_seats: get(outboundData, 'outboundSeats'),
  },
  ...returnEnabled && {
    return_start: get(returnData, 'returnStart').format(DATE_FORMAT.filter),
    return_available_seats: get(returnData, 'returnSeats'),
    return_origin_address: get(returnData, 'returnAddress'),
  },
});
