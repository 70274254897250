<template>
  <div class="CarpoolingTripCard align-self-center">
    <GenericCardWrapperComponent
      v-bind="$attrs"
      :open="enabled"
    >
      <template #header>
        <div class="d-flex align-items-baseline m-4">
          <p class="emobg-font-weight-bold emobg-font-medium">
            {{ $t(`views.carpooling.${tripName}_trip_title`) }}
          </p>
        </div>
        <div class="ml-auto m-4">
          <ui-toggle
            :value="enabled"
            :data-test-id="`${tripName}_trip-toggle`"
            :text="$t(`views.carpooling.toggle_${tripName}_info_label`)"
            :side="SIDES.left"
            @changevalue="event => $emit('change:enable-toggle', event.detail)"
          />
        </div>
      </template>
      <template #collapsable-content>
        <div
          v-if="enabled"
          data-test-id="card-content"
          class="m-4"
        >
          <div class="d-flex my-3 justify-content-between">
            <slot name="directions" />
          </div>
          <div class="py-2 d-flex">
            <div class="w-50">
              <slot name="date-time" />
            </div>
            <div class="w-50 pl-3">
              <slot name="seats" />
            </div>
          </div>
        </div>
      </template>
    </GenericCardWrapperComponent>
  </div>
</template>

<script>
import { SIDES } from '@emobg/web-components';
import GenericCardWrapperComponent from '@/components/GenericCardWrapper/GenericCardWrapperComponent';
import CARPOOLING_TRIPS from './constants/trips';

export default {

  name: 'CarpoolingTripCard',
  components: {
    GenericCardWrapperComponent,
  },

  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    tripName: {
      type: String,
      default: CARPOOLING_TRIPS.outgoing,
    },
  },
  created() {
    this.SIDES = SIDES;
  },

};

</script>
<style lang="scss">
.CarpoolingTripCard {
  width: 100%;
}
</style>
