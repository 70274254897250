var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'GenericCardWrapperComponent',
    'emobg-background-color-white',
    {
      'my-4 emobg-border-color-ground emobg-border-radius-medium emobg-border-1 emobg-shadow-s': _vm.border
    }

  ]},[_c('div',{staticClass:"d-flex",class:{
      'emobg-border-bottom-1 emobg-border-color-ground': _vm.$slots.content,
    },attrs:{"data-test-id":"card-header"}},[_vm._t("header")],2),_c('div',{attrs:{"data-test-id":"card-body"}},[_vm._t("body")],2),_c('ui-collapse',_vm._b({},'ui-collapse',_vm.$attrs,false),[_vm._t("collapsable-content")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }