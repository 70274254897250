import { computed } from 'vue';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import {
  LOG_TYPE, logger, snakeCaseKeys, trackSegmentEvent, trackSegmentGroupEvent, trackSegmentIdentifyEvent,
} from '@emobg/web-utils';

import { doesCompanyOwnsData, userCompanyUuid, userUuid } from '@/stores/User/UserData/UserDataMapper';
import { getCurrentCity } from '@/stores/City/CityMapper';
import { getCurrentProfileType } from '@/stores/User/Profile/ProfileMapper';

const EMPLOYEE_DATA_FIELDS = [
  'uuid',
  'name',
  'first_name',
  'last_name',
  'email',
  'phone',
  'idp_uuid',
  'user_uuid',
];

const currentStore = {
  doesCompanyOwnsData,
  userCompanyUuid,
  getCurrentCity,
  getCurrentProfileType,
  userUuid,
};

export const useSegment = (store = currentStore) => {
  const companyOwnsData = computed(store.doesCompanyOwnsData);
  const companyUuid = computed(store.userCompanyUuid);
  const currentCity = computed(store.getCurrentCity);
  const currentProfileType = computed(store.getCurrentProfileType);
  const user = computed(store.userUuid);

  const sanitizeTrackingData = trackingData => (companyOwnsData.value
    ? omit(trackingData, EMPLOYEE_DATA_FIELDS)
    : trackingData);

  const formatTrackingData = trackingData => ((isString(trackingData) || isNumber(trackingData))
    ? { value: trackingData }
    : trackingData);

  const trackSegment = ({ name, data = {} }) => {
    logger.message(`tracking [${name}] Segment event`, LOG_TYPE.warning);

    if (!name) {
      logger.message('Segment event name is missing', LOG_TYPE.warning);
      return;
    }

    const userSegmentData = {
      uuid: get(companyUuid, 'value'),
      profileType: get(currentProfileType, 'value'),
      city: get(currentCity, 'value.code'),
      userUuid: get(user, 'value'),
    };

    const identifiedTrackingData = snakeCaseKeys({
      ...formatTrackingData(data),
      ...userSegmentData,
    });

    trackSegmentEvent(name, sanitizeTrackingData(identifiedTrackingData));
  };

  const trackSegmentGroup = ({ id, data = {} }) => {
    const identifiedTrackingData = snakeCaseKeys({
      ...formatTrackingData(data),
    });
    trackSegmentGroupEvent(id, sanitizeTrackingData(identifiedTrackingData));
  };

  const trackSegmentIdentify = ({ id, data = {} }) => {
    const identifiedTrackingData = snakeCaseKeys({
      ...formatTrackingData(data),
    });
    trackSegmentIdentifyEvent(id, sanitizeTrackingData(identifiedTrackingData));
  };

  return {
    trackSegment,
    trackSegmentGroup,
    trackSegmentIdentify,
  };
};
