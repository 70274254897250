import map from 'lodash/map';
import isNil from 'lodash/isNil';
import toString from 'lodash/toString';

/**
 * Returns an array with populate a Select to choose the number of seats up to the availableSeats passed
 * @param {Number} availableSeats
 * @return {Array}
 */
export const getSeatsOptions = availableSeats => (isNil(availableSeats)
  ? []
  : map(new Array(availableSeats), (item, index) => ({ label: toString(index + 1), value: index + 1 })));
