<template>
  <CarpoolingTripCard
    v-bind="$attrs"
    :trip-name="carpoolingTrips.outgoing"
    @change:enable-toggle="value => $emit('change:enabled', value)"
  >
    <template #directions>
      <div class="w-100">
        <ui-text-input
          ref="originStation"
          :value="originStation"
          :label="$t('views.carpooling.from_label')"
          data-test-id="outgoing_trip-start_address"
          disabled
          class="w-100"
          name="outgoing-location"
        />
      </div>
      <ui-icon
        :icon="ICONS.arrowForward"
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.ink"
        class="mx-2 mt-5 pt-2"
      />
      <div class="w-100">
        <ui-text-input
          ref="outgoing-destination"
          v-validate.input="{ isRequired: true }"
          :value="outboundAddressValue"
          :label="$t('views.carpooling.to_label')"
          :placeholder="$t('views.carpooling.outgoing_destination_placeholder')"
          data-test-id="outgoing_trip-destination_address"
          class="w-100"
          name="outgoing-destination"
          @changevalue="({ detail }) => $emit('change:outbound-address', detail)"
        />
      </div>
    </template>
    <template #date-time>
      <div style="width: 95%;">
        <ui-text-input
          :value="formatDate(startDateTime, dateFormat)"
          :label="$t('views.carpooling.from_label')"
          class="w-80"
          disabled
        />
      </div>
    </template>
    <template #seats>
      <ui-select
        :value="seatsValue"
        :label="$t('views.carpooling.available_spots_label')"
        :options.prop="seatsOptions"
        :normalize="false"
        data-test-id="outgoing_trip-seats"
        class="w-75"
        name="outgoing-seats"
        @selectoption="({ detail }) => $emit('change:seats', detail)"
      />
    </template>
  </CarpoolingTripCard>
</template>

<script>
import { DATE_FORMAT, formatDate } from '@emobg/web-utils';

import { Validate } from '@emobg/vue-base';

import CarpoolingTripCard from '@/domains/Carpooling/components/CarpoolingTripCard/CarpoolingTripCard';
import CARPOOLING_TRIPS from '@/domains/Carpooling/components/CarpoolingTripCard/constants/trips';
import { getSeatsOptions } from '@/domains/Carpooling/components/helpers/carpoolingHelpers';

export default {
  name: 'CarpoolingOutgoingCard',

  components: {
    CarpoolingTripCard,
  },
  directives: {
    Validate,
  },

  props: {
    originStation: {
      type: String,
      required: true,
    },
    outboundAddress: {
      type: String,
      default: '',
    },
    startDateTime: {
      type: String,
      required: true,
    },
    seats: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      outboundAddressValue: '',
      seatsValue: '',
    };
  },

  created() {
    this.carpoolingTrips = CARPOOLING_TRIPS;
    this.dateFormat = `${DATE_FORMAT.timeOfDay} DD ${DATE_FORMAT.monthShort}`;
    this.seatsOptions = getSeatsOptions(this.seats);

    this.outboundAddressValue = this.outboundAddress;
    this.seatsValue = this.seats;
  },
  methods: { formatDate },
};
</script>
