<template>
  <MainViewLayout v-if="renderComponent">
    <template slot="main-content">
      <FeedbackModalComponent
        v-model="modal.isOpen"
        v-bind="modal"
      />
      <div class="Carpooling LayoutPage">
        <div class="d-flex flex-column py-4 emobg-background-color-ground-lightest">
          <MuiValidationWrapper @form-validation="onClickConfirmCarpooling">
            <template slot-scope="{ areAllValid }">
              <div class="align-items-start ml-4 mb-4 d-flex flex-column">
                <div class="d-flex align-items-baseline ml-5 py-1">
                  <ui-icon
                    :icon="ICONS.arrowBack"
                    :size="ICONS_SIZES.small"
                    :color="COLORS.primary"
                    class="cursor-pointer"
                    hover
                    data-test-id="navigate_back-booking-summary"
                    @click="goBack"
                  />

                  <div class="emobg-font-weight-bold emobg-font-large ml-2">
                    {{ $t('views.carpooling.title') }}
                  </div>
                </div>
                <div class="emobg-font-weight-bold emobg-font-medium pt-1 ml-5">
                  {{ $t('views.carpooling.subtitle') }}
                </div>
              </div>
              <div class="d-flex flex-column mx-6">
                <CarpoolingOutgoingCard
                  :enabled="trips.outBound.enabled"
                  :origin-station="reservation.location.name"
                  :outbound-address="outboundAddress"
                  :start-date-time="reservation.start"
                  :seats="outboundSeats"
                  @change:enabled="value => trips.outBound.enabled = value"
                  @change:outbound-address="value => outboundAddress = value"
                  @change:seats="value => outboundSeats = value"
                />
                <CarpoolingReturnCard
                  :enabled="trips.return.enabled"
                  :return-station="reservation.location.name"
                  :return-address="returnAddress"
                  :return-start="returnStart"
                  :seats="returnSeats"
                  :start-date-time="reservation.start"
                  :end-date-time="reservation.end"
                  @change:enabled="value => trips.return.enabled = value"
                  @change:return-address="value => returnAddress = value"
                  @change:seats="value => returnSeats = value"
                  @change:return-start="value => returnStart = value"
                />

                <div class="Carpooling__actions d-flex justify-content-around w-100">
                  <ui-button
                    v-bind="fetchButtonSpecs()"
                    data-test-id="submit_booking_carpooling-button"
                    :disabled="!areAllValid || areTripsDisabled"
                    :loading="loading"
                    :type="BUTTON_TYPES.submit"
                  >
                    {{ $t('views.carpooling.btn_confirm_text') }}
                  </ui-button>
                </div>
              </div>
            </template>
          </MuiValidationWrapper>
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>

<script>
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pick from 'lodash/pick';
import size from 'lodash/size';

import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import { external } from '@emobg/web-api-client';
import {
  DATE_FORMAT, isFalsyString, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/web-components';
import * as BookingModule from '@/vue/stores/Booking/BookingStore';
import * as DateTimeModule from '@/vue/stores/DateTime/DateTimeStore';
import * as AvailabilityModule from '@Vehicle/store/Availability/AvailabilityStore';
import { nameSpace as companyNameSpace } from '@/vue/stores/Company/CompanyStore';
import {
  ACTIONS as VehicleUserActions,
  GETTERS as VehicleUserGetters,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';
import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';
import { NAMESPACE as CarpoolingModuleNameSpace } from '@/domains/Carpooling/store/CarpoolingModule';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import SegmentMixin from '@/mixins/Segment';

import Api from '@/vue/api/backoffice';
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

import BookingRoutesNames from '@Bookings/router/routes-names';
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';

import { bookingConfirmationModalParams } from '@/helpers/booking/bookingSummaryHelpers';
import { genericErrorArgs } from '@/constants/defaultModalArgs';

import {
  SEGMENT_ENUMERATE_VALUES,
  SEGMENT_EVENTS,
  SEGMENT_PARAM_NAMES,
} from '@/vue/constants';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { ADDITIONAL_DRIVERS_FILTER, ADDITIONAL_PASSENGERS_FILTER } from '@/constants/vehicleUserTypes.const';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { getBookingUuid, getBookingVehicleSeats } from '@/helpers/booking/bookingHelpers';
import { getCarpoolingCreationParams, getPrebookingRequestParams } from '@/helpers/booking/apiHelpers';
import { useTheme } from '@/composable/Theme/useTheme';
import CarpoolingOutgoingCard from '@/domains/Carpooling/components/CarpoolingOutgoingCard/CarpoolingOutgoingCard';
import CarpoolingReturnCard from '@/domains/Carpooling/components/CarpoolingReturnCard/CarpoolingReturnCard';
import { useSegment } from '@/composable/Segment/segment';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';

export default {
  name: 'Carpooling',
  components: {
    MainViewLayout,
    MuiValidationWrapper,
    FeedbackModalComponent,
    CarpoolingOutgoingCard,
    CarpoolingReturnCard,
  },

  directives: {
    Validate,
  },
  mixins: [
    SegmentMixin,
  ],

  setup() {
    const { trackSegment: trackSegmentEvent } = useSegment();
    const { fetchButtonSpecs } = useTheme();
    return { trackSegmentEvent, fetchButtonSpecs };
  },

  data() {
    return {
      renderComponent: true,
      manager: null,
      loading: false,
      trips: {
        outBound: {
          enabled: true,
        },
        return: {
          enabled: true,
        },
      },
      modal: {
        isOpen: false,
      },

    };
  },

  computed: {
    ...mapState(BookingModule.nameSpace, [
      'reservation',
      'bookingSummary',
    ]),

    ...mapGetters(BookingModule.nameSpace, [
      'isBookingWithCarpooling',
      'createBookingRequestData',
      'createRideShareRequestData',
      'isBookingOnBehalf',
    ]),

    ...mapGetters(companyNameSpace, [
      'getCompanyData',
    ]),

    ...mapGetters(
      BookingCostAllocationModule.NAMESPACE,
      {
        costAllocationPayload:
            CostAllocationDataModule.GETTERS.apiPayloadCurrentCostAllocation,
      },
    ),

    ...mapGetters(
      VehicleUserNamespace,
      {
        getVehicleUsers: VehicleUserGetters.data,
        getVehicleUsersCount: VehicleUserGetters.count,
      },
    ),

    areTripsDisabled() {
      return !this.trips.outBound.enabled && !this.trips.return.enabled;
    },

    operatorUuid() {
      return get(this, 'reservation.operator.uuid');
    },
  },

  created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    if (!this.isBookingWithCarpooling) {
      this.renderComponent = false;
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    }

    const isDedicatedFleet = !get(this, 'reservation.location.isOpen');
    const isAdditionalDriverEnabled = get(this, 'getCompanyData.isAdditionalDriverAllowed');

    const isAdditionalDriverAvailable = isDedicatedFleet && isAdditionalDriverEnabled;

    const driversEmails = this.getVehicleUsers(ADDITIONAL_DRIVERS_FILTER);
    this.additionalDriversEmails = map(driversEmails, 'data');

    this.shouldUpdateDrivers = isAdditionalDriverAvailable && size(this.additionalDriversEmails);

    const passengers = this.getVehicleUsers(ADDITIONAL_PASSENGERS_FILTER);
    this.passengersList = map(passengers, 'data');

    const passengerCount = this.getVehicleUsersCount(ADDITIONAL_PASSENGERS_FILTER);
    const driverCount = this.getVehicleUsersCount(ADDITIONAL_DRIVERS_FILTER);
    const freeSeats = getBookingVehicleSeats({ booking: this.reservation }) - driverCount - passengerCount - 1;
    const freeSeatsSanitize = Math.max(0, freeSeats);

    this.outboundAddress = '';
    this.returnAddress = '';
    this.outboundSeats = freeSeatsSanitize;
    this.returnSeats = freeSeatsSanitize;
    this.returnStart = moment(get(this, 'reservation.end'), DATE_FORMAT.dateTime).subtract(15, 'minutes');
  },

  methods: {
    fetchCSOperator,

    ...mapActions(AvailabilityModule.nameSpace, {
      resetAvailabilityStore: AvailabilityModule.ACTIONS.resetAvailabilityStore,
    }),
    ...mapActions(
      VehicleUserNamespace,
      {
        updateVehicleUserDrivers: VehicleUserActions.updateDrivers,
      },
    ),

    ...mapActions(BookingModule.nameSpace, {
      resetBookingState: BookingModule.ACTIONS.resetState,
    }),

    ...mapActions(DateTimeModule.nameSpace, {
      resetDateTimeState: DateTimeModule.ACTIONS.resetState,
    }),

    ...mapMutations(BookingModule.nameSpace, ['setCarpoolingData']),

    ...mapActions(CarpoolingModuleNameSpace, ['createCarpooling']),

    goBack() {
      this.$router.back();
    },

    async onClickConfirmCarpooling({ isValid }) {
      this.loading = true;

      if (!isValid) {
        this.loading = false;
        return;
      }

      let booking;

      const isPreBooking = get(this, 'reservation.isPreBooking');

      const bookingStep = isPreBooking
        ? this.createPreBooking
        : this.createBooking;

      try {
        booking = await bookingStep();
        booking = { ...booking, isPreBooking };

        const bookingUuid = getBookingUuid({ booking });

        this.trackBookingCreation({ bookingCreated: true, bookingUuid });

        if (!isFalsyString(bookingUuid)) {
          await this.carpoolingStep(booking);
        }
        if (!isPreBooking) {
          await this.additionalDriverStep(bookingUuid);
        }

        this.bookingFinishedStep(booking);
      } catch (error) {
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        logger.message(`There was a problem creating booking with carpooling: ${error.message}`, LOG_TYPE.error);
        const unsuccessfulReason = errorMessage || this.$t('carsharing_personal.error.booking_no_created');
        this.trackBookingCreation({ bookingCreated: !isNil(booking), unsuccessfulReason });

        this.modal = {
          ...genericErrorArgs(this.$t),
          description: errorMessage,
          primaryCallToAction: () => { this.modal = { ...this.modal, isOpen: false }; },
          isOpen: true,
        };
      } finally {
        this.loading = false;
      }
    },

    async createBooking() {
      const bookingParams = this.createBookingRequestData();
      const costAllocations = size(this.costAllocationPayload) ? this.costAllocationPayload : [];

      const reservationRequestParameters = {
        ...bookingParams,
        company_cost_allocations: costAllocations,
        passengers: this.passengersList,
      };

      return get(await Api.carsharing.setBooking(reservationRequestParameters), 'data');
    },

    async createPreBooking() {
      const { reservation, passengersList, costAllocationPayload } = this;

      const additionalDriversEmails = this.shouldUpdateDrivers ? this.additionalDriversEmails : [];

      const payload = getPrebookingRequestParams({
        costAllocations: costAllocationPayload,
        additionalDriversEmails,
        carpooling: true,
        passengersList,
        reservation,
      });
      return get(await external.preBooking.postPreBooking(payload), 'data');
    },

    confirmReservation(isPrebooking) {
      let redirectRoute = BookingRoutesNames.myBookings;

      if (this.isBookingOnBehalf) {
        redirectRoute = isPrebooking
          ? CompanyAdminRoutesNames.employeeBookingRequests
          : CompanyAdminRoutesNames.employeeBookingsList;
      }

      this.modal.isOpen = false;
      this.resetBookingState();
      this.resetAvailabilityStore();
      this.resetDateTimeState();

      this.$router.push({ name: redirectRoute });
    },

    async carpoolingStep(booking) {
      const payload = getCarpoolingCreationParams({
        outBoundEnabled: this.trips.outBound.enabled,
        returnEnabled: this.trips.return.enabled,
        outboundData: pick(this, ['outboundAddress', 'outboundSeats']),
        returnData: pick(this, ['returnStart', 'returnSeats', 'returnAddress']),
        booking,
      });

      return this.createCarpooling(payload);
    },

    async additionalDriverStep(bookingUuid) {
      if (this.shouldUpdateDrivers) {
        return this.updateVehicleUserDrivers({ bookingUuid, emails: this.additionalDriversEmails });
      }
      return true;
    },

    bookingFinishedStep(booking) {
      const location = get(booking, 'location', {});
      const isPreBooking = isFalsyString(get(booking, 'uuid'));

      this.modal = {
        ...bookingConfirmationModalParams(location, isPreBooking),
        primaryCallToAction: () => this.confirmReservation(isPreBooking),
      };

      this.modal.isOpen = true;
    },

    async trackBookingCreation(params) {
      const { bookingCreated, unsuccessfulReason, bookingUuid } = params;
      const google = googleMapsLoader.getInstance();

      const locationPosition = {
        lat: get(this, 'reservation.location.lat'),
        lng: get(this, 'reservation.location.lng'),
      };

      const userDistance = this.distanceBetweenLocations(
        google,
        get(this, 'reservation.user.userGeolocation'),
        locationPosition,
      );

      const userAddressDistance = this.distanceBetweenLocations(
        google,
        get(this, 'reservation.user.addressGeolocation'),
        locationPosition,
      );

      const {
        CAR_SHARING,
        LONG_DISTANCE,
      } = get(SEGMENT_ENUMERATE_VALUES, 'BOOKING_TYPE', {});

      const bookingTypes = {
        [BOOKING_TYPES.carsharing]: CAR_SHARING,
        [BOOKING_TYPES.longDistance]: LONG_DISTANCE,
      };

      const type = get(this, 'reservation.booking_type');
      const BOOKING_TYPE = bookingTypes[type];

      this.trackSegmentEvent({
        name: SEGMENT_EVENTS.CARSHARING_BOOKING_CREATED,
        data: {
          [SEGMENT_PARAM_NAMES.DISTANCE_FROM_THE_USER]: userDistance,
          [SEGMENT_PARAM_NAMES.DISTANCE_FROM_THE_USER_ADDRESS]: userAddressDistance,
          [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: BOOKING_TYPE,
          [SEGMENT_PARAM_NAMES.VALUE]: get(this, 'bookingSummary.price.total_raw'),
          [SEGMENT_PARAM_NAMES.CURRENCY]: await this.getCurrencyCode(),
          [SEGMENT_PARAM_NAMES.INSURANCE_TYPE]: get(this, 'bookingSummary.current_insurance.internal_name'),
          [SEGMENT_PARAM_NAMES.CAR_CATEGORY]: get(this, 'reservation.vehicle.category'),
          [SEGMENT_PARAM_NAMES.CAR_MODEL]: get(this, 'reservation.vehicle.model'),
          [SEGMENT_PARAM_NAMES.SUCCESSFUL_BOOKING_DONE]: bookingCreated,
          [SEGMENT_PARAM_NAMES.UNSUCCESSFUL_BOOKING_DONE_REASON]: unsuccessfulReason,
          [SEGMENT_PARAM_NAMES.VEHICLE_ID]: get(this, 'reservation.vehicle.uuid') || get(this, 'bookableData.vehicle.category'),
          [SEGMENT_PARAM_NAMES.BOOKING_ID]: bookingUuid,
        },
      });
    },

    async getCurrencyCode() {
      const operator = await this.fetchCSOperator(this.operatorUuid);

      return get(operator, 'configuration.currency_code');
    },
  },
};

</script>
<style lang="scss">
.Carpooling {
  &__actions {
    width: 444px;
  }
}
</style>
