import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import size from 'lodash/size';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import values from 'lodash/values';
import pick from 'lodash/pick';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { DATE_FORMAT } from '@emobg/web-utils';
import GENERIC_MODAL_TYPES from '@Shared/components/FeedbackModal/constants/genericModalTypes';
import { parseApiErrorMessage } from '@/utils/apiHelpers';

import { useTranslations } from '@/composable/App/Translations';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, ILLUSTRATIONS_KEYNAMES } from '@/constants/assetsRepository';

const { $t, $i18n } = useTranslations();

/**
 * Return params to display confirmation modal on BookingSummary (including Carpooling flow)
 * @param location Object
 * @param isPreBooking Boolean
 * @param isRecurrentBooking Boolean
 * @param errors Array
 * @return Object
 */
export const bookingConfirmationModalParams = (location, isPreBooking, isRecurrentBooking = false, errors = []) => {
  const isGeofence = get(location, 'is_geofence', false);

  const title = isPreBooking
    ? $t('modal.prebooking_confirm.title')
    : $t('modal.booking_confirm.title');

  let description = isGeofence
    ? $t('modal.booking_zone_completed.text')
    : $t('modal.booking_confirm.subtitle');

  let errorMessage;

  if (isPreBooking) {
    description = $t('modal.prebooking_confirm.description');
  }

  if (size(errors)) {
    const groupedErrors = mapValues(
      groupBy(errors, 'key'),
      group => map(
        group,
        bookingFailed => values(
          map(
            pick(bookingFailed, ['startDate']),
            date => moment(date).format(DATE_FORMAT.isoDate),
          ),
        ),
      ),
    );

    errorMessage = join(
      map(
        groupedErrors,
        (bookingsDates, errorPhraseAppKey) => `<div class="d-flex flex-column"><span class="mt-2 mb-1">${parseApiErrorMessage($t, $i18n, errorPhraseAppKey)}</span> <span>${join(bookingsDates, ' , ')} <span></div>`,
      ),
      '<br/>',
    );
  }

  const image = isGeofence && !isEmpty(location.image)
    ? location.image
    : getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.ILLUSTRATIONS, ILLUSTRATIONS_KEYNAMES.BOOKING_CONFIRMED);
  const imageWithPadding = isGeofence && !isEmpty(location.image);

  const extraInfo = isRecurrentBooking
    ? $t('modal.booking_confirm.recurrent_booking_dl_reminder')
    : '';

  return {
    title,
    description,
    image,
    imageWithPadding,
    errorMessage,
    extraInfo,
    primaryCallToActionText: $t('buttons.ok'),
    testIdSuffix: GENERIC_MODAL_TYPES.success,
  };
};
