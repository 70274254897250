<template>
  <CarpoolingTripCard
    v-bind="$attrs"
    :trip-name="carpoolingTrips.return"
    @change:enable-toggle="value => $emit('change:enabled', value)"
  >
    <template #directions>
      <div class="w-100">
        <ui-text-input
          ref="returnAddressValue"
          v-validate.input="{ isRequired: true }"
          :value="returnAddressValue"
          :label="$t('views.carpooling.from_label')"
          :placeholder="$t('views.carpooling.return_starting_point_placeholder')"
          data-test-id="return_trip-start_address"
          class="w-100"
          name="return-location"
          @changevalue="({ detail }) => $emit('change:return-address', detail)"
        />
      </div>
      <ui-icon
        :icon="ICONS.arrowBack"
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.ink"
        class="mx-2 mt-5 pt-2"
      />
      <div class="w-100">
        <ui-text-input
          ref="returnStation"
          :value="returnStation"
          :label="$t('views.carpooling.to_label')"
          data-test-id="return_trip-destination_address"
          disabled
          class="w-100"
          name="return-destination"
        />
      </div>
    </template>
    <template #date-time>
      <div style="width: 95%;">
        <ui-datetimepicker
          id="carpoolingDateTime"
          :date.prop="returnStart"
          :label="$t('views.carpooling.from_label')"
          :valid-date-range.prop="validDateRange"
          data-test-id="return_datetime-picker"
          class="w-100 mr-3"
          @datechanged="({ detail }) => $emit('change:return-start', detail)"
        />
      </div>
    </template>
    <template #seats>
      <ui-select
        v-model="seatsValue"
        :label="$t('views.carpooling.available_spots_label')"
        :options.prop="seatsOptions"
        :normalize="false"
        data-test-id="return_trip-seats"
        class="w-100 mt-2"
        name="return-seats"
        @selectoption="({ detail }) => $emit('change:seats', detail)"
      />
    </template>
  </CarpoolingTripCard>
</template>

<script>
import { Validate } from '@emobg/vue-base';

import { DATE_FORMAT } from '@emobg/web-utils';

import languageService from '@/services/Language';

import CarpoolingTripCard from '@/domains/Carpooling/components/CarpoolingTripCard/CarpoolingTripCard';
import CARPOOLING_TRIPS from '@/domains/Carpooling/components/CarpoolingTripCard/constants/trips';
import { getSeatsOptions } from '@/domains/Carpooling/components//helpers/carpoolingHelpers';

export default {
  name: 'CarpoolingReturnCard',

  components: {
    CarpoolingTripCard,
  },
  directives: {
    Validate,
  },

  props: {
    returnStation: {
      type: String,
      required: true,
    },
    returnAddress: {
      type: String,
      default: '',
    },
    returnStart: {
      type: Object,
      required: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
    endDateTime: {
      type: String,
      required: true,
    },
    seats: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      manager: null,
      returnAddressValue: '',
      seatsValue: '',
    };
  },

  computed: {
    currentLanguage() {
      return languageService.getLanguage();
    },
    validDateRange() {
      return {
        start: this.manager.minimum,
        end: this.manager.maximum,
      };
    },
  },

  created() {
    this.dateFormats = DATE_FORMAT;
    this.dayFormat = `DD ${DATE_FORMAT.monthShort}`;
    this.carpoolingTrips = CARPOOLING_TRIPS;
    this.seatsOptions = getSeatsOptions(this.seats);

    this.manager = {
      initial: this.returnStart,
      minimum: moment(this.startDateTime, DATE_FORMAT.dateTime).subtract(1, 'second'),
      maximum: moment(this.endDateTime, DATE_FORMAT.dateTime),
    };

    this.returnAddressValue = this.returnAddress;
    this.seatsValue = this.seats;
  },

};
</script>
