export const VEHICLE_USER_TYPES = {
  passenger: 'PASSENGER',
  driver: 'DRIVER',
  carpooling: 'CARPOOLING',
};

export const VEHICLE_USER_ID = {
  bookingSummary: 'BOOKING_SUMMARY',
};

export const ADDITIONAL_PASSENGERS_FILTER = {
  id: VEHICLE_USER_ID.bookingSummary,
  type: VEHICLE_USER_TYPES.passenger,
};

export const ADDITIONAL_DRIVERS_FILTER = {
  id: VEHICLE_USER_ID.bookingSummary,
  type: VEHICLE_USER_TYPES.driver,
};

