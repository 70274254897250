<template>
  <div
    :class="[
      'GenericCardWrapperComponent',
      'emobg-background-color-white',
      {
        'my-4 emobg-border-color-ground emobg-border-radius-medium emobg-border-1 emobg-shadow-s': border
      }

    ]"
  >
    <div
      :class="{
        'emobg-border-bottom-1 emobg-border-color-ground': $slots.content,
      }"
      class="d-flex"
      data-test-id="card-header"
    >
      <slot name="header" />
    </div>
    <div data-test-id="card-body">
      <slot name="body" />
    </div>
    <ui-collapse v-bind="$attrs">
      <slot name="collapsable-content" />
    </ui-collapse>
  </div>
</template>

<script>
export default {
  name: 'GenericCardWrapperComponent',

  props: {
    border: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
